
import { computed, defineComponent, onMounted, ref, watchEffect, getCurrentInstance, onBeforeMount,watch   } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import QuotesService from "@/core/services/car/QuotesService";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
// import CustomerService from "@/core/services/car/CustomerService";
import UserService from "@/core/services/UserService";
import InvoiceService from "@/core/services/car/InvoiceService";
import { boolean } from "yup/lib/locale";
import Multiselect from "@vueform/multiselect";
import CustomerService from "@/core/services/car/CustomerService";
import PolicyService from "@/core/services/car/PolicyService";
import moment from "moment";
import { ElNotification } from "element-plus";
import { Mutations } from "@/store/enums/StoreEnums";
import InvoiceModal from "./InvoiceModal.vue";
import { notif, validation, roles,otherGenders,paymentMethodLabel } from '@/store/stateless/store';
import { useUpdateCustomerData } from "@/store/composable/Customer";
import mainSocket from "@/main-socket";
import PaymentWarning from '@/components/modals/PaymentWarning.vue'
import { isPaymentWarning } from "@/store/stateless/common";
import { common } from "@/store/stateless/common";

interface CarDetailsBodyParam {
  model_id: string;
  year: string;
}
interface AddInvoiceBodyParam {
  driver_details_id: string;
  policy_sales_agent_id: any;
  bai_car_insurance_policy_id: string;
  customer_id: string;
  lead_id: string;
  is_agency_repair: string;
  original_price: number;
  sale_price: number;
  payment_type: string;
  order_description: string;
  language: string;
  expiry_date_time: string;
  is_amend: string;
  invoice_doc: string;
  intransact_policy_id: any;
  is_discount_given:string;
  discount_amount:number;
  gender:string;
  reference_no:any;
  payment_date:any;
  admin_fee_amount: number,
  admin_extra_charges: number,
  promo_code:any,
  is_split_payment:string;
  split_payment_installments_count:string;
  installments:any;
}

interface SplitPaymentInstallmentsParam {
  installment_no          : string,
  installment_payment_date:string,
  installment_amount      : string,
  installment_cib_receipt : any
}

export default defineComponent({
  name: "Generate-Invoice",
  props: {},
  components: { Multiselect,InvoiceModal, PaymentWarning},
  data() {
    return {
      dob: "",
      policyBegin: "",
      registrationDate: "",
      emirates: [
        "",
        "Abu Dhabi",
        "Ajman",
        "Dubai",
        "Fujairah",
        "Ras Al Khaimah",
        "Sharjah",
        "Umm Al Quwain",
      ],
      dlExperience: [
        "",
        "0-6 months",
        "6 - 12 months",
        "1 - 2 Years",
        "More than 2 Years",
      ],
      claim_certificate: [
        "",
        "No - I have no certificate",
        "Yes - 1 year with proof",
        "Yes - 2 years with proof",
        "Yes - 3 or more years with proof",
      ],
    };
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1900 },
        (value, index) => 2021 - index
      );
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const inst = getCurrentInstance()
    const disable_sale_price = ref(false);
    const showContentLoader = ref(false);
    const disabledButton = ref(false)
    let carDetailsBodyParam = {} as CarDetailsBodyParam;
    let customerEmail = ref<string>("");
    let addInvoiceBodyParam = ref<AddInvoiceBodyParam>({
      policy_sales_agent_id: null,
      driver_details_id: route.params.quoteId.toString(),
      bai_car_insurance_policy_id: "",
      customer_id: route.params.customerId.toString(),
      lead_id: "-1",
      is_agency_repair: "",
      original_price: 0,
      sale_price: 0,
      payment_type: "",
      order_description: "",
      language: "en",
      expiry_date_time: "",
      is_amend: "0",
      invoice_doc: "",
      intransact_policy_id: null,
      is_discount_given:"0",
      discount_amount: 0,
      gender:"",
      reference_no:"",
      payment_date:"",
      admin_fee_amount: 0,
      admin_extra_charges: 0,
      promo_code:'',
      is_split_payment:"0",
      split_payment_installments_count:"",
      installments:[]
    });
    let splitPaymentInstallmentsParam = ref<SplitPaymentInstallmentsParam>({
        installment_no          : "",
        installment_payment_date: "",
        installment_amount      : "0",
        installment_cib_receipt : ""
    });

    let isFormValid = ref<boolean>(true);
    const paymentWarningRef = ref()
    const rules = ref({
      email: {
        show: false,
        message: "Please enter Customer Email",
      },
      expiry_date: {
        show: false,
        message: "Expiry date is mandatory for online",
      },
      policy_agent: {
        show: false,
        message: "Please select Policy agent",
      },
      invoice_plan: {
        show: false,
        message: "Please select Insurance Plan",
      },
      agency_repair: {
        show: false,
        message: "Please select Agency Repair",
      },
      language: {
        show: false,
        message: "Please select language",
      },
      sale_price: {
        show: false,
        message: "Please enter Sales price",
      },
      order_description: {
        show: false,
        message: "Please enter Order Description",
      },
      payment_type: {
        show: false,
        message: "Please select Payment Type",
      },
      file_data: {
        show: false,
        message: `Please upload file for ${paymentMethodLabel.cod}/${paymentMethodLabel.exchange}/${paymentMethodLabel.cashDeposit}`,
      },
      intransact_policy_id: {
        show: false,
        message: "Quote Ref No. required for Intransact",
      },
      discount_amount: {
        show: false,
        message: "Please enter Discount Amount",
      },
      original_price: {
        show: false,
        message: "Please enter Policy Price",
      },
      claimed_insurance: {
        show: false,
        message: "Please Insurance Claims",
      },
      claims: {
        show: false,
        message: "Please enter Claims.",
      },
      no_claim_certificate: {
        show: false,
        message: "Please select the No claim certificate",
      },
      is_new: {
        show: false,
        message: "Please select car is brand new ",
      },
      existing_policy_expired: {
        show: false,
        message: "Please select policy active",
      },
      uae_driving_experience: {
        show: false,
        message: "Please select the Experience",
      },
      driving_experience: {
        show: false,
        message: "Please select the Experience",
      },
      third_party_liability: {
        show: false,
        message: "Please select existing policy TPL only ",
      },
      policy_start_date: {
        show: false,
        message: "Please select Policy Start Date",
      },
      gender: {
        show: false,
        message: "Please select the Gender ",
      },
      is_discount_given: {
        show: false,
        message: "Select Yes as there is a discount",
      },
      reference_no: {
        show: false,
        message: "Please enter Reference No.",
      },
      payment_date: {
        show: false,
        message: "Please select payment date",
      },
      promo_code:{
        show:false,
        message:"Invalid promotion code"
      },
      original_price_error:{
        show:false,
        message:"Incorrect amount"
      },
      sale_price_error:{
        show:false,
        message:"Incorrect amount"
      },
      admin_fee_error:{
        show:false,
        message:"Incorrect amount"
      },
      admin_extra_charges_error:{
        show:false,
        message:"Incorrect amount"
      },
      is_split_payment: {
        show: false,
        message: "Select Yes as if its split payment",
      },
      split_payment_installments_count: {
        show: false,
        message: "Please select Number of Installments",
      },
      installment_payment_date:{
        show:false,
        message:"Please select installment date",
        index:[] as string[]
      },
      installment_amount:{
        show:false,
        message:"Please enter installment amount",
        index:[] as string[]
      },
      installment_cib_receipt:{
        show:false,
        message:"Please enter Internal Receipt No.",
        index:[] as string[]
      },
      installment_amount_total:{
        show:false,
        type:''
      },
      installment_cib_exists:{
        show:false,
        message:"Internal Receipt no. already exists",
        index:[] as string[]
      },
    });
    let salesAgentList = ref({});
    const isError = ref<boolean>(false);
    const hasPendingInvoice = ref()

    onMounted(() => {

      mainSocket.channel(`invoice-generated-car-${addInvoiceBodyParam.value?.driver_details_id}`)
        .listen('MakeinvoiceEvent', (data: any) => {
          router.push({
            name: "customer-details",
            params: { customerId: addInvoiceBodyParam.value?.customer_id },
            query: { lead_id: addInvoiceBodyParam.value?.lead_id }
          });
        })

      if(!roles.customerdetails.includes(roles.userId())) window.location.href = '/#/404'
      

      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Make Invoice", ["Leads & Tasks"]);

      QuotesService.getCountries();
      // CustomerService.getCustomerDetails(store, route.params.customerId);
      // CustomerService.getSkyeAgentList(store);
      UserService.getDomSalesAgentsManagerTeamLeader().then((result) => {
        salesAgentList.value = result.data.data.agents;
      });
      QuotesService.fetchQuote({ driver_details_id: route.params.quoteId })
      .then((data: any) => {
        let agent = editQuote.value.lead ? editQuote.value.lead.agent : null;
        addInvoiceBodyParam.value.policy_sales_agent_id  = agent;
        if(editQuote.value && editQuote.value.has_pending_invoice && hasPendingInvoice.value) {
          hasPendingInvoice.value.open(true)
        } else {
          const insurancePlanRef:any = inst?.refs.insurancePlanRef
          insurancePlanRef.focus()
          window.scrollTo(0,2000);
        }

        if(data?.data?.quote?.lead?.promo_code?.status == 1) {
          addInvoiceBodyParam.value.promo_code = data?.data?.quote?.lead?.promo_code?.promotion_code
        }

        // addInvoiceBodyParam.value.is_agency_repair = editQuote.value?.lead.vehicle_type == 2 ? "0" : "1";
        InvoiceService.getInsurancePolicyListWithFilter({policy_for:editQuote.value?.lead.vehicle_type});

      });
      
      QuotesService.isLeadRenewalPending({ driver_details_id: route.params.quoteId })
      .then((data:any) => {
        
        if(data.data){
          window.scrollTo(0,2000);
        }
        
      });
      // InvoiceService.getInsurancePolicyList();
    });
    let expiryDate = ref<string>("");
    let paymentDate = ref<string>("");
    let expiryDateTime: any;
    expiryDateTime = "";
    const getExpiryDateTime = (dateTime) => {
      const dateformart = moment(
        dateTime.target.value,
        "DD/MM/YYYY",
        true
      ).isValid()
        ? moment(dateTime.target.value, "DD/MM/YYYY").format("YYYY-MM-DD")
        : dateTime.target.value;
      var dueDateTime = new Date(dateformart);
      expiryDateTime =
        dueDateTime.getFullYear() +
        "-" +
        (dueDateTime.getMonth() + 1) +
        "-" +
        dueDateTime.getDate();
    };

    let errMsg = ref<boolean>(false);

    const isClick = ref<boolean>(false);

    // const openSuccessMessage = () => {
    //   ElNotification({
    //     title: "Invoice Created",
    //     message: "",
    //     type: "success",
    //   });
    // };

    const openErrorMessage = (message) => {
      ElNotification({
        title: "Validation Error",
        dangerouslyUseHTMLString: true,
        message: message,
        duration: 0,
        type: "warning",
      });
    };

    const serializeArrayOfObjects = (array,type) => {
        const params = new URLSearchParams();
        array.forEach((item, index) => {
            Object.keys(item).forEach(key => {
                params.append(`${type}[${index}][${key}]`, item[key]);
            });
        });
        return params.toString();
    };

    const invoiceModalRef = ref()
    const addInvoice = async(inputData) => {
    if(addInvoiceBodyParam.value.payment_type == '1' && Number(addInvoiceBodyParam.value.sale_price) <= 0) {
      notif.error('Invoice Error', 'You cannot select Payment type as Online if Sales Price is 0')
      return
    } 

      isError.value = false;
      isDecimalValidPrice.value = true;
      isDecimalValidSalesPrice.value = true;
      rules.value.is_discount_given.show = false;
      errorMessages.value = [];
      isFormValid.value = true;

      let formData = new FormData();
      //
      if (expiryDateTime == "") {
        var dueDateTime = new Date();
        expiryDateTime =
          dueDateTime.getFullYear() +
          "-" +
          (dueDateTime.getMonth() + 1) +
          "-" +
          dueDateTime.getDate() +
          " " +
          moment(dueDateTime.toLocaleTimeString(), "hh:mm A").format("HH:mm");
      }
      addInvoiceBodyParam.value.lead_id = inputData.lead.id;
      formData.append(
        "policy_sales_agent_id",
        addInvoiceBodyParam.value.policy_sales_agent_id
      );
      formData.append("lead_id", addInvoiceBodyParam.value.lead_id);
      formData.append("customer_id", addInvoiceBodyParam.value.customer_id);
      formData.append(
        "driver_details_id",
        addInvoiceBodyParam.value.driver_details_id
      );
      formData.append(
        "bai_car_insurance_policy_id",
        addInvoiceBodyParam.value.bai_car_insurance_policy_id
      );
      formData.append(
        "is_agency_repair",
        addInvoiceBodyParam.value.is_agency_repair
      );
      formData.append(
        "original_price",
        addInvoiceBodyParam.value.original_price.toString()
      );
      formData.append("sale_price", addInvoiceBodyParam.value.sale_price.toString());
      formData.append("payment_type", addInvoiceBodyParam.value.payment_type);
      formData.append("reference_no", addInvoiceBodyParam.value.reference_no);
      //For COD or Direct
      if(addInvoiceBodyParam.value.payment_type == '2' || addInvoiceBodyParam.value.payment_type == '3' || addInvoiceBodyParam.value.payment_type == '5' || addInvoiceBodyParam.value.payment_type == '6')
      {
        formData.append("payment_date", paymentDate.value);
      }
      formData.append(
        "order_description",
        addInvoiceBodyParam.value.order_description
      );
      formData.append("language", addInvoiceBodyParam.value.language);
      formData.append("expiry_date_time", expiryDate.value);
      formData.append("is_amend", addInvoiceBodyParam.value.is_amend);
      formData.append("car_value", inputData.car_value);
      formData.append("is_new", inputData.lead.is_new);
      formData.append("current_policy_active", inputData.current_policy_active);
      formData.append(
        "existing_policy_expired",
        inputData.current_policy_active == 2 ? "1" : "2"
      );
      formData.append("policy_start_date", inputData.policy_start_date);
      formData.append("fully_comprehensive", inputData.fully_comprehensive);
      formData.append(
        "third_party_liability",
        inputData.fully_comprehensive == 2 ? "1" : "2"
      );
      formData.append("driving_experience", inputData.driving_experience);
      formData.append(
        "uae_driving_experience",
        inputData.uae_driving_experience
      );
      formData.append("claimed_insurance", inputData.claimed_insurance);
      formData.append("no_claim_certificate", inputData.no_claim_certificate);
      formData.append("lead_status", inputData.lead.lead_status_id);
      // formData.append("driver_details_id", inputData.id);
      formData.append("claims", inputData.claims);
      formData.append("invoice_doc", file);
      formData.append("gender", inputData.gender);
      if (addInvoiceBodyParam.value.payment_type == "4") {
        formData.append(
          "intransact_policy_id",
          addInvoiceBodyParam.value.intransact_policy_id
        );
      }

   
      formData.append("is_discount_given", addInvoiceBodyParam.value.is_discount_given);
      formData.append("discount_amount", addInvoiceBodyParam.value.discount_amount.toString());
      formData.append("admin_fee_amount", addInvoiceBodyParam.value.admin_fee_amount.toString());
      formData.append("admin_extra_charges", addInvoiceBodyParam.value.admin_extra_charges.toString());
      formData.append("promo_code", addInvoiceBodyParam.value.promo_code.toString());
      formData.append("is_split_payment", addInvoiceBodyParam.value.is_split_payment);
      formData.append("installment_count", addInvoiceBodyParam.value.split_payment_installments_count);
      
      if(addInvoiceBodyParam.value.is_split_payment !== '' && addInvoiceBodyParam.value.installments.length > 0){
        // formData.append("installments", serializeArrayOfObjects(addInvoiceBodyParam.value.installments,'installments'));
        formData.append("installments", encodeURIComponent(JSON.stringify(addInvoiceBodyParam.value.installments)));
      }
     

      validateInvoiceForm();
      if(inputData?.customer?.email != '' && !inputData?.customer?.email)
      {
          if (customerEmail.value == "") {
            rules.value.email.show = true;
            isFormValid.value = false;
            window.scrollTo(0, 0);
          } 
          else if (customerEmail.value != "" && !validation.validateEmail(customerEmail.value)) {
            rules.value.email.show = true;
            rules.value.email.message = 'Please enter valid email address';
            isFormValid.value = false;
            window.scrollTo(0, 0);
          }

          formData.append("email", customerEmail.value);
      }


      // let data = "<ul>";
      // let i = 0;
      // for (i; i < errorMessages.value.length; i++) {
      //   if (errorMessages.value[i]) {
      //     data += '<li style="color:red;">' + errorMessages.value[i] + "</li>";
      //   }
      // }
      // data += "</ul>";
      if (isFormValid.value) {
      isPaymentWarning.value = true

      isClick.value = true;
      disabledButton.value = true
        const data = await InvoiceService.addInvoice(formData)

        if(data.status < 299 || data.data.data.has_pending_invoice) {
            isClick.value = false;
            isPaymentWarning.value = false
            isError.value = false;
            let fetchInvoice = computed(() => {
              return store.getters.fetchInvoice;
            });

            let invoice = fetchInvoice.value;
            if(data.data.data.has_pending_invoice) {
              invoice = {
                ...invoice,
                hasPending: true
              }
            }

            store.commit(Mutations.SET_INVOICE_MODAL);
            store.commit(Mutations.SET_INVOICE_MODAL_VALUE, invoice);

            if(data.status < 299)
            notif.simple("Make Invoice", "success", "You have successfully initiated Make invoice!");

            invoiceModalRef.value.toFirst()
        } else {
            if(data.data.data.reference_no) {
              rules.value.reference_no.show = true;
              rules.value.reference_no.message = 'Reference no. already exists'
            }
            // if(data.data.data.cib_reference_no) {
            //   let existingErrors  = rules.value.installment_cib_exists.index;
            //   existingErrors.push(data.data.data.index);
            //   rules.value.installment_cib_exists.index = existingErrors;
            //   rules.value.installment_cib_exists.show = true;
            //   isFormValid.value = false;
            // }

            isClick.value = false;
            isError.value = data.data.data.has_invoice_created ? false  :true;
            disabledButton.value = false
            isPaymentWarning.value = false
        }
          // .then(() => {
          //   isClick.value = false;
          //   isError.value = false;
          //   let fetchInvoice = computed(() => {
          //     return store.getters.fetchInvoice;
          //   });

          //   const invoice = fetchInvoice.value;

          //   store.commit(Mutations.SET_INVOICE_MODAL);
          //   store.commit(Mutations.SET_INVOICE_MODAL_VALUE, invoice);
          //   notif.simple("Make Invoice", "success", "You have successfully initiated Make invoice!");
          //   invoiceModalRef.value.toFirst()
          // })
          // .catch((error) => {
          //   console.log('error',error)
          //   isClick.value = false;
          //   isError.value = true;
          // });
      } else {
        focusField()
        scrollPage();
        isError.value = false;       
        isClick.value = false;
        isPaymentWarning.value = false
      }
    };
    const scrollPage = () => {
      
      if(rules.value.gender.show){
        window.scrollTo(0,900);
      }
    }
    const errorMessages = ref<Array<string>>([]);
    const isDecimalValidPrice = ref(true);
    let is_agency_repair_disabled = ref(false);
    const isDecimalValidSalesPrice = ref(true);
    const validateInvoiceForm = () => {
      let decimalPlaces = /^[0-9]*(\.[0-9]{0,2})?$/;
      // 
      if(addInvoiceBodyParam.value.is_discount_given == "1")
      {
        if (addInvoiceBodyParam.value.discount_amount == 0) {
          rules.value.discount_amount.show = true;
          isFormValid.value = false;
        }
      }

      if (addInvoiceBodyParam.value.original_price  >= 1000000000) {
        rules.value.original_price_error.show = true;
        rules.value.original_price_error.message = 'Incorrect amount';
        isFormValid.value = false;
      }

      if (addInvoiceBodyParam.value.sale_price  >= 1000000000) {
        rules.value.sale_price_error.show = true;
        rules.value.sale_price_error.message = 'Incorrect amount';
        isFormValid.value = false;
      }

      if (addInvoiceBodyParam.value.admin_fee_amount  >= 1000000000) {
        rules.value.admin_fee_error.show = true;
        rules.value.admin_fee_error.message = 'Incorrect amount';
        isFormValid.value = false;
      }

      if (addInvoiceBodyParam.value.admin_extra_charges  >= 1000000000) {
        rules.value.admin_extra_charges_error.show = true;
        rules.value.admin_extra_charges_error.message = 'Incorrect amount';
        isFormValid.value = false;
      }

      if(addInvoiceBodyParam.value.original_price  >= 1000000000 || addInvoiceBodyParam.value.sale_price  >= 1000000000) {
        isFormValid.value = false;
        // notif.simple("Please Note", "warning", 'Incorrect amount field entered.', 0);
      }

      if (addInvoiceBodyParam.value.discount_amount > 0 && addInvoiceBodyParam.value.is_discount_given == "0") {
          rules.value.is_discount_given.show = true;
          isFormValid.value = false;
      }

      if (addInvoiceBodyParam.value.payment_type == "1") {
        if (expiryDate.value == "") {
          rules.value.expiry_date.show = true;
          errorMessages.value.push("Please select Payment Type");
          isFormValid.value = false;
        }
        if (addInvoiceBodyParam.value.language == "-1") {
          rules.value.language.show = true;
          errorMessages.value.push("Please select language");
          isFormValid.value = false;
        }
      }

      if (
        addInvoiceBodyParam.value.payment_type == "2" ||
        addInvoiceBodyParam.value.payment_type == "5" ||
        addInvoiceBodyParam.value.payment_type == "6" ||
        addInvoiceBodyParam.value.payment_type == "3"
      ) {
        if (file == undefined) {
          rules.value.file_data.show = true;
          errorMessages.value.push("Please upload file for COD");
          isFormValid.value = false;
        }
        else
        {
          rules.value.file_data.show = false;
        }

        if(addInvoiceBodyParam.value.reference_no == ""){
          rules.value.reference_no.show = true;
          isFormValid.value = false;
        }
        else if(addInvoiceBodyParam.value.reference_no != "" && addInvoiceBodyParam.value.reference_no.length > 30){
          rules.value.reference_no.show = true;
          rules.value.reference_no.message = "Reference No. should be less than 30 characters";
          isFormValid.value = false;
        }
        else
        {
          rules.value.reference_no.show = false;
        }

        if(paymentDate.value == ""){
          rules.value.payment_date.show = true;
          isFormValid.value = false;
        }
        


      }

      if (addInvoiceBodyParam.value.payment_type == "4") {
        if (addInvoiceBodyParam.value.intransact_policy_id == null) {
          rules.value.intransact_policy_id.show = true;
          errorMessages.value.push("Quote Ref No. required for Intransact");
          isFormValid.value = false;
        }
      }
      if (addInvoiceBodyParam.value.policy_sales_agent_id == null) {
        rules.value.policy_agent.show = true;
        errorMessages.value.push("Please select Policy agent");
        window.scrollTo(0, 0);
        isFormValid.value = false;
      }
      // if (addInvoiceBodyParam.value.language == "-1") {
      //   rules.value.language.show = true;
      //   errorMessages.value.push('Please select language')
      //   isFormValid.value = false;
      // }
      if (addInvoiceBodyParam.value.original_price == 0) {
        rules.value.original_price.show = true;
        isFormValid.value = false;
      }    
      // if(addInvoiceBodyParam.value.original_price && !decimalPlaces.test(addInvoiceBodyParam.value.original_price)){
      //   rules.value.original_price.message = 'Please enter upto 2 decimal places only';
      //   rules.value.original_price.show = true;
      //   isFormValid.value = false;
      //   isDecimalValidPrice.value = false;
      // }
      // if(addInvoiceBodyParam.value.sale_price && !decimalPlaces.test(addInvoiceBodyParam.value.sale_price)){
      //   rules.value.sale_price.message = 'Please enter upto 2 decimal places only';
      //   rules.value.sale_price.show = true;
      //   isFormValid.value = false;
      //   isDecimalValidSalesPrice.value = false;
      // }
      if (addInvoiceBodyParam.value.sale_price == 0 && validateSalePrice.value) {
        rules.value.sale_price.show = true;
        errorMessages.value.push("Please enter Sales price");
        isFormValid.value = false;
      }

      
      // if(addInvoiceBodyParam.value.sale_price != "" && addInvoiceBodyParam.value.original_price != ""  && parseFloat(addInvoiceBodyParam.value.sale_price) > parseFloat(addInvoiceBodyParam.value.original_price))
      // {
      //   rules.value.sale_price.show = true;
      //   rules.value.sale_price.message = "Sale Price cannot be greater than Policy Price";
      //   isFormValid.value = false;
      // }

      if (addInvoiceBodyParam.value.bai_car_insurance_policy_id == "") {
        rules.value.invoice_plan.show = true;
        errorMessages.value.push("Please select Invoice Plan");
        isFormValid.value = false;
      }
      if (addInvoiceBodyParam.value.is_agency_repair == "") {
        rules.value.agency_repair.show = true;
        errorMessages.value.push("Please select Agency Repair");
        isFormValid.value = false;
      }
      if (addInvoiceBodyParam.value.payment_type == "") {
        rules.value.payment_type.show = true;
        errorMessages.value.push("Please select Payment Type");
        isFormValid.value = false;
      }
      if (addInvoiceBodyParam.value.order_description == "") {
        rules.value.order_description.show = true;
        errorMessages.value.push("Please enter Order Description");
        isFormValid.value = false;
        isClick.value = false;
      }

      if (editQuote.value.gender == null || editQuote.value.gender == "") {
        rules.value.gender.show = true;
        isFormValid.value = false;
      }else{
         rules.value.gender.show = false;
      }
      //Validation for driver details on lead renewal
      if(leadStatus.value == 5)
      {
        if (
          (editQuote.value.no_claim_certificate == null || editQuote.value.no_claim_certificate == "") &&
          editQuote.value.claimed_insurance == 2
        ) {
          rules.value.no_claim_certificate.show = true;
          isFormValid.value = false;
        }

        if(editQuote.value.claimed_insurance == 1 && (!editQuote.value.claims || editQuote.value.claims < 0)) {
          rules.value.claims.show = true;
          isFormValid.value = false;
        }
 
        if (
          editQuote.value.uae_driving_experience == null ||
          editQuote.value.uae_driving_experience == 0
        ) {
          rules.value.uae_driving_experience.show = true;
          isFormValid.value = false;
        }
        if (
          editQuote.value.driving_experience == null ||
          editQuote.value.driving_experience == 0
        ) {
          rules.value.driving_experience.show = true;
          isFormValid.value = false;
        }

        if (
          editQuote.value.lead.is_new == null ||
          editQuote.value.lead.is_new == ""
        ) {
          rules.value.is_new.show = true;
          isFormValid.value = false;
        }
      

        if (
          editQuote.value.existing_policy_expired == null ||
          editQuote.value.existing_policy_expired == ""
        ) {
          rules.value.existing_policy_expired.show = true;
          isFormValid.value = false;
        }

        if (
          editQuote.value.third_party_liability == null ||
          editQuote.value.third_party_liability == ""
        ) {
          rules.value.third_party_liability.show = true;
          isFormValid.value = false;
        }

        if (
          editQuote.value.policy_start_date == null ||
          editQuote.value.policy_start_date == ""
        ) {
          rules.value.policy_start_date.show = true;
          isFormValid.value = false;
        }
      }

      /**
       * Installments Validations
       */
      let invoiceBodyParams = addInvoiceBodyParam.value;
      if(invoiceBodyParams.is_split_payment == "1")
      {
        if (invoiceBodyParams.split_payment_installments_count == "") {
          rules.value.split_payment_installments_count.show = true;
          errorMessages.value.push("Please select Number of Installments");
          isFormValid.value = false;
        }

        if (invoiceBodyParams.installments) {
          // Initialize arrays separately
          let amountIndex: string[] = [];
          let paymentDateIndex: string[] = [];
          let cibReceiptIndex: string[] = [];
          let totalInstallmentAmount= 0;
          
          // Use forEach instead of map since we are not returning a new array
          invoiceBodyParams.installments.forEach(function(ins) {
            
            let key = (Number(ins.installment_no) - 1).toString();;
            let installmentAmount = 0;
            if (!ins.installment_amount || ins.installment_amount.length <= 0 || parseFloat(ins.installment_amount) <= 0) {
              amountIndex.push(key);
            }else{
              installmentAmount = parseFloat(ins.installment_amount);
            }

            if (!ins.installment_payment_date || ins.installment_payment_date === '' || ins.installment_payment_date.length <= 0) {
              paymentDateIndex.push(key);
            }
            if (key == '0' && (!ins.installment_cib_receipt || ins.installment_cib_receipt === '')) {
              cibReceiptIndex.push(key);
            }

            totalInstallmentAmount += installmentAmount;
          });

          if(amountIndex.length > 0){
            rules.value.installment_amount.show = true;
            rules.value.installment_amount.index = amountIndex;
            isFormValid.value = false;
          }
          if(paymentDateIndex.length > 0){
            rules.value.installment_payment_date.show = true;
            rules.value.installment_payment_date.index = paymentDateIndex;
            isFormValid.value = false;
          }
          if(cibReceiptIndex.length > 0){
            rules.value.installment_cib_receipt.show = true;
            rules.value.installment_cib_receipt.index = cibReceiptIndex;
            isFormValid.value = false;
          }
          
          if(totalInstallmentAmount != addInvoiceBodyParam.value.sale_price){
            rules.value.installment_amount_total.show = true;
            rules.value.installment_amount_total.type = (totalInstallmentAmount > addInvoiceBodyParam.value.sale_price) ? 'higher' : 'lower';
            isFormValid.value = false;
          }else{
            rules.value.installment_amount_total.show = false;
            rules.value.installment_amount_total.type = '';
          }
        }
      }

    };

    let agentList = ref({
      data: [],
      options: salesAgentList,
      placeholder: "Please Select Policy Sales Agent",
      searchable: true,
      createTag: true,
    });
    const onSalesAgentChange = (event) => {
      addInvoiceBodyParam.value.policy_sales_agent_id = event;
    };
    const resetSalesAgent = () => {
      addInvoiceBodyParam.value.policy_sales_agent_id = null;
    };
    const showInvoiceDom = computed(() => {
      return store.getters.getInvoiceDomStatus;
    });
    let file: any;
    const handleFileUpload = (event) => {
      rules.value.file_data.show = false
      rules.value.file_data.message = "Please upload file for COD/OMT Payment/Cash deposit"
      isFormValid.value = true

      // formData = new FormData();
      rules.value.file_data.show = false;
      file = event.target.files[0];
      if (file == "" && addInvoiceBodyParam.value.payment_type != "1") {
        rules.value.file_data.show = false;
        isFormValid.value = false;
      }

      if((event.target.files[0].size / 1024 / 1024) > 2) {
        rules.value.file_data.show = true
        rules.value.file_data.message = "Document size exceed limit of 2mb"
        isFormValid.value = false
      }
      //
    };
    const setPaymentMode = () => {
      rules.value.payment_type.show = false
      if (addInvoiceBodyParam.value.payment_type == "3") {
        // console.log(addInvoiceBodyParam.value.payment_type );
        copyPlan();
      } else {
        addInvoiceBodyParam.value.order_description = "";
      }

      addInvoiceBodyParam.value.admin_extra_charges = 0
      addInvoiceBodyParam.value.reference_no = ''

      if(masterAdmin.value.cod_active == 1 && addInvoiceBodyParam.value.payment_type == "2") {
        addInvoiceBodyParam.value.admin_extra_charges = masterAdmin.value.cod_amount
      }

      //For Instrancat --> call cancelled policies 
      if (addInvoiceBodyParam.value.payment_type == "4") {
        PolicyService.getCancelledPoliciesList({
          customer_id: route.params.customerId,
        });
      }
      isFormValid.value = false;

      rules.value.file_data.show = false;
      rules.value.expiry_date.show = false;
      
      if(!['2', '5', '6'].includes(addInvoiceBodyParam.value.payment_type)){
        addInvoiceBodyParam.value.split_payment_installments_count="";
        addInvoiceBodyParam.value.is_split_payment="0";
      }

    };
    const updateCustomerEmail = () => {
      if (customerEmail.value == "") {
        rules.value.email.show = true;
        window.scrollTo(0, 0);
      } 
      else if (customerEmail.value != "" && !validation.validateEmail(customerEmail.value)) {
        rules.value.email.show = true;
        rules.value.email.message = 'Please enter valid email address';
        window.scrollTo(0, 0);
      }
      else {
        Promise.all([
          CustomerService.updateCustomerEmail({
            customer_id: route.params.customerId.toString(),
            email: customerEmail.value,
          }),
        ])
          .then((data) => {
            isEmailError.value = false;
            QuotesService.fetchQuote({ driver_details_id: route.params.quoteId });
          })
          .catch(() => {
            // isEmailError.value = true;
            // console.log('error')
          });
      }
    };
    let disableCustomerEmailButton = computed(() => {
      return store.getters.getDisableCustomerEmailButton;
    });

    const yearMinusOne = computed(() => {
      const datenow = new Date();
      const day = datenow.getDate();
      const month = datenow.getUTCMonth();
      const year = datenow.getFullYear();
      return new Date(year, month, day);
    });

    const disabledDate = (time: any) => {
      return time.getTime() < new Date(yearMinusOne.value);
    };
    let getCancelledPoliciesList = computed(() => {
      return store.getters.getCancelledPolicies;
    });

    let cancelledPoliciesList = ref({
      data: [],
      options: getCancelledPoliciesList,
      placeholder: "Select Quote Ref No.",
      searchable: true,
      createTag: false,
    });

    const onSelectQuoteRefNo = (event) => {
      addInvoiceBodyParam.value.intransact_policy_id = event;
    };
    const resetQuoteRefNo = () => {
      addInvoiceBodyParam.value.intransact_policy_id = null;
    };

    const isEmailError = ref(false);

    const onChangeInsurancePlan = () => {
        // addInvoiceBodyParam.value.bai_car_insurance_policy_id =  event;
        rules.value.invoice_plan.show = false
        rules.value.agency_repair.show = false
        copyPlan();
        if(addInvoiceBodyParam.value.original_price > 0) {
          validatePromoCode()
        }
    }

    const copyPlan = () => {
      const data = policyList.value.find(
          (item) =>
            item.id == addInvoiceBodyParam.value.bai_car_insurance_policy_id
      );
      console.log(data);

      addInvoiceBodyParam.value.admin_fee_amount = 0

      if(data.policy_type == 5){
        addInvoiceBodyParam.value.is_agency_repair = "0";
        if(masterAdmin.value.admin_fee == 1) addInvoiceBodyParam.value.admin_fee_amount = masterAdmin.value.admin_fee_tpl
        is_agency_repair_disabled.value = true;
      }
      else
      {
        if(masterAdmin.value.admin_fee == 1) addInvoiceBodyParam.value.admin_fee_amount = masterAdmin.value.admin_fee_comprehensive
        is_agency_repair_disabled.value = false;

      }
      if (addInvoiceBodyParam.value.payment_type == "3") {
        
        if (data && data !== undefined) {
          addInvoiceBodyParam.value.order_description = data.policy_name;
        } else {
          addInvoiceBodyParam.value.order_description = "-1";
        }
      }
    };

    const policyList = computed(() => {
      return store.getters.getPolicyLists;
    });

    const editQuote = computed(() => {
      return store.getters.getEditQuote;
    });

    const leadStatus = computed(() => {
      return editQuote.value.lead ? editQuote.value.lead.lead_status_id : 0;
    });


    const getPolicyExpiry = (event) => {
      editQuote.value.existing_policy_expired = parseInt(event.target.value);
      editQuote.value.current_policy_active = event.target.value == 2 ? 1 : 2
        // leadDriverDetailsBodyParam.value.existing_policy_expired == 1 ? 2 : 1;
    };

    const getThirdParty = (event) => {
      editQuote.value.third_party_liability = parseInt(event.target.value);
      editQuote.value.fully_comprehensive = event.target.value == 1 ? 2 : 1
    };

    const homeExperience = ref({
      placeholder: "Please Select Experience",
      value: "4",
      options: [
        { value: "1", label: "0-6 months" },
        { value: "2", label: "6 - 12 months" },
        { value: "3", label: "1 - 2 Years" },
        { value: "4", label: "More than 2 Years" },
      ],
      searchable: true,
      clearOnSelect: true,
    });

    const uaeExperience = ref({
      placeholder: "Please Select Experience",
      value: editQuote.value.driving_experience,
      options: [
        { value: "1", label: "0-6 months" },
        { value: "2", label: "6 - 12 months" },
        { value: "3", label: "1 - 2 Years" },
        { value: "4", label: "More than 2 Years" },
      ],
      searchable: true,
      clearOnSelect: true,
    });

    const ncc = ref({
      placeholder: "Please Select",
      value: editQuote.value.no_claim_certificate,
      options: [
        { value: 1, label: "No - I have no certificate" },
        { value: 2, label: "Yes - 1 year with proof" },
        { value: 3, label: "Yes - 2 years with proof" },
        { value: 4, label: "Yes - 3 or more years with proof" },
      ],
      searchable: true,
      clearOnSelect: true,
    });

    const setDate = (e) => {
      return moment(e, "YYYY-MM-DD").format("DD/MM/YYYY");
    };

    const changeDiscountGiven = (event) => {
      rules.value.discount_amount.show = false;
      rules.value.is_discount_given.show = false;
      addInvoiceBodyParam.value.is_discount_given = event.target.value;
    }

    let companyList = computed(() => {
      return store.getters.getPolicyLists;
    });

    // let companyList = ref({
    //   placeholder: "Please Select",
    //   value: null,
    //   options: getPolicyLists,
    //   searchable: true,
    //   createTag: true,
    // });
    
    const onChangePolicyPrice = () => {
      addInvoiceBodyParam.value.sale_price = addInvoiceBodyParam.value.original_price;
      calculateDiscountAmount();
    }

    const fixFigure = () => {
      addInvoiceBodyParam.value.sale_price = roundUp(parseFloat(addInvoiceBodyParam.value.sale_price.toString().replace(',', '')))
      if(isNaN(addInvoiceBodyParam.value.sale_price)) {
        addInvoiceBodyParam.value.sale_price = 0
      }
    }

    const fixFigureAdminFee = () => {
      addInvoiceBodyParam.value.admin_fee_amount = roundUp(parseFloat(addInvoiceBodyParam.value.admin_fee_amount.toString().replace(',', '')))
      if(isNaN(addInvoiceBodyParam.value.admin_fee_amount)) {
        addInvoiceBodyParam.value.admin_fee_amount = 0
      }
    }

    const fixFigureAdminExtra= () => {
      addInvoiceBodyParam.value.admin_extra_charges = roundUp(parseFloat(addInvoiceBodyParam.value.admin_extra_charges.toString().replace(',', '')))
      if(isNaN(addInvoiceBodyParam.value.admin_extra_charges)) {
        addInvoiceBodyParam.value.admin_extra_charges = 0
      }
    }

    const calculateDiscountAmount = () => {
      addInvoiceBodyParam.value.promo_code = '';
      addInvoiceBodyParam.value.discount_amount = 0;
      rules.value.promo_code.show=false;
      const val = parseFloat(addInvoiceBodyParam.value.sale_price.toString().replace(',', ''))
      if(addInvoiceBodyParam.value.original_price > 0 && val > 0)
      {

        if(val > addInvoiceBodyParam.value.original_price)
        {
          addInvoiceBodyParam.value.discount_amount = 0;
        }
        else
        {
          let discountAmount = addInvoiceBodyParam.value.original_price - val;

          addInvoiceBodyParam.value.discount_amount = roundUp(discountAmount);
        }
        
      }
      
    }

    let agencyRepairList = ref({
      placeholder: "Please Select",
      data:[],
      value: null,
      options:[
        { value: "1", label: "Yes" },
        { value: "0", label: "No" }
      ],
      searchable: true,
      createTag: true,
    });
    
    const onChangeAgencyRepair = (event) => {
      addInvoiceBodyParam.value.is_agency_repair =  event;
    }

    const openModal = computed(() => {
      return store.getters.getInvoiceModal;
    });

    const closeModal = (e) => {
      
      store.commit(Mutations.SET_INVOICE_MODAL);
    };

    const editClaimsCertificate = ref(null);
    const editClaims = ref(null);
    const resetClaims = () => {
      if (editQuote.value.claimed_insurance == 1) {
        editClaimsCertificate.value = editQuote.value.no_claim_certificate;
     
        ncc.value.value = null;
        editQuote.value.no_claim_certificate  = null;
        editQuote.value.claims = editClaims.value ? editClaims.value : 1;
      } else {
        editClaims.value = editQuote.value.claims;
        editQuote.value.claims = null;
 
        editQuote.value.no_claim_certificate  = editClaimsCertificate.value ? editClaimsCertificate.value : 1;
        ncc.value.value = editQuote.value.no_claim_certificate;
      }
    };

    // const ProceedModal = (e) => {
    //   isError.value = false;
    //   // store.commit(Mutations.SET_MODAL_LOADING);
    //   // Promise.all([InvoiceService.updateInvoice(e)])
    //   //   .then((data) => {
    //   //     fetchInvoiceList(bodyParameters);
    //   //     store.commit(Mutations.SET_MODAL_LOADING);
    //   //     store.commit(Mutations.SET_MODAL);
    //   //   })
    //   //   .catch((err) => {
    //   //     isError.value = true;
    //   //     store.commit(Mutations.SET_MODAL_LOADING);
    //   //   });
    // };
    const getPolicyBeginDate = (policyBegin) => {
      var policyTS = new Date(policyBegin.target.value);
      editQuote.value.policy_start_date =
        policyTS.getFullYear() +
        "-" +
        (policyTS.getMonth() + 1) +
        "-" +
        policyTS.getDate();
    };

    onBeforeRouteLeave(() => {
      ElNotification.closeAll();
    })

    const onChangeEmail = () => {
      rules.value.email.show = false;
    }



    const addDayDate = computed(() => {
      const today = new Date()
      const tomorrow = new Date(today)
      return moment(tomorrow.setDate(tomorrow.getDate() + 1)).format("YYYY-MM-DD")
    });

    const updateSalePriceValue = (e) => {
      rules.value.original_price.show = false
      const val = parseFloat(e.target.value.toString().replace(',', ''))
      if(isNaN(val)) {
        addInvoiceBodyParam.value.sale_price = 0
      } else {
        addInvoiceBodyParam.value.sale_price = roundUp(val);
      }
      validatePromoCode();
    }

    watchEffect(() => {
      
      if(addInvoiceBodyParam.value.sale_price < addInvoiceBodyParam.value.original_price) {
          addInvoiceBodyParam.value.discount_amount = roundUp(addInvoiceBodyParam.value.original_price - addInvoiceBodyParam.value.sale_price);
        }
      else {
          addInvoiceBodyParam.value.discount_amount = 0;
      }
    })


    const onPaymentDateChange = ()=>{

      addInvoiceBodyParam.value.installments.forEach((installment,index) => {
          // installment.installment_payment_date = (index == 0) ? paymentDate.value : '';
          // installment.defDate = (index == 0) ? paymentDate.value : '';
            let paymentDateVal = paymentDate.value;
            let defDate        = '';
            let installmentPaymentDate        = '';

            if(paymentDateVal){
                if(index == 0){
                  installmentPaymentDate = paymentDateVal;
                  defDate = paymentDateVal;
                  let insPaymentDate = rules.value.installment_payment_date.index;
                  removeIndex(index,insPaymentDate);
                }else{
                  let prevIndex   = (index-1);
                  defDate = addInvoiceBodyParam.value.installments[prevIndex].installment_payment_date;
                }
            }
            
            installment.defDate                  = defDate; 
            installment.installment_payment_date = installmentPaymentDate; 
        });
    }

    const roundUp = (num: number) => {
            var p = Math.pow(10, 2);
            return Math.round(num * p) / p;
        }

    const focusField = () => {
      const { gender, invoice_plan, agency_repair, original_price, sale_price, payment_type, order_description, reference_no, expiry_date } = rules.value

      if(gender.show) {
        const genderRef:any = inst?.refs.genderRef
        genderRef.focus()
      }

      if(!gender.show &&
        invoice_plan.show
      ) {
        const insurancePlanRef:any = inst?.refs.insurancePlanRef
        insurancePlanRef.focus()
      }

      if(!gender.show &&
        !invoice_plan.show &&
        agency_repair.show
      ) {
        const agenyRepairRef:any = inst?.refs.agenyRepairRef
        agenyRepairRef.focus()
      }

      if(!gender.show &&
        !invoice_plan.show &&
        !agency_repair.show &&
        original_price.show
      ) {
        const originalPriceRef:any = inst?.refs.originalPriceRef
        originalPriceRef.isFocus()
      }

      if(!gender.show &&
        !invoice_plan.show &&
        !agency_repair.show &&
        !original_price.show &&
        sale_price.show
      ) {
        const salePriceRef:any = inst?.refs.salePriceRef
        salePriceRef.isFocus()
      }

      if(!gender.show &&
        !invoice_plan.show &&
        !agency_repair.show &&
        !original_price.show &&
        !sale_price.show &&
        payment_type.show
      ) {
        const paymentTypeRef:any = inst?.refs.paymentTypeRef
        paymentTypeRef.focus()
      }
      
      if(!gender.show &&
        !invoice_plan.show &&
        !agency_repair.show &&
        !original_price.show &&
        !sale_price.show &&
        !payment_type.show &&
        order_description.show
      ) {
        const orderDescriptionRef:any = inst?.refs.orderDescriptionRef
        orderDescriptionRef.focus()
      }
      
      if(!gender.show &&
        !invoice_plan.show &&
        !agency_repair.show &&
        !original_price.show &&
        !sale_price.show &&
        !payment_type.show &&
        !order_description.show &&
        reference_no.show
      ) {
        const referenceNoRef:any = inst?.refs.referenceNoRef
        referenceNoRef.focus()
      }

      if(!gender.show &&
        !invoice_plan.show &&
        !agency_repair.show &&
        !original_price.show &&
        !sale_price.show &&
        !payment_type.show &&
        !order_description.show &&
        expiry_date.show
      ) {
        const expiryDateRef:any = inst?.refs.expiryDateRef
        expiryDateRef.isFocus()
      }
    }

    const toLanguage = () => {
        const languageRef:any = inst?.refs.languageRef
        languageRef.focus()
    }

    const toOrderDescription = () => {
        const orderDescriptionRef:any = inst?.refs.orderDescriptionRef
        orderDescriptionRef.focus()
    }

    const toExpiryDate = () => {
      const expiryDateRef:any = inst?.refs.expiryDateRef
      expiryDateRef.isFocus()
    }

    const toCancel = () => {
      const saveRef:any = inst?.refs.saveRef
        saveRef.focus()
    }

    const masterAdmin = computed(() => {
      return store.getters.getMasterAdmin
    })

    const cancenRef = ref()

    const splitPaymentDisabled = ref(false)
    const validateSalePrice = ref(true)


    async function validatePromoCode() {
      const {
        original_price,
        customer_id,
        bai_car_insurance_policy_id,
        promo_code,
      } = addInvoiceBodyParam.value;

     rules.value.promo_code.show=false;
     rules.value.promo_code.message="";

     splitPaymentDisabled.value = false
     validateSalePrice.value = true
     
     addInvoiceBodyParam.value.is_discount_given = "0";
     
    if (promo_code) {
      if (original_price > 0 && bai_car_insurance_policy_id) {
        showContentLoader.value = true;
        const promoPayload = {
          promo_code: promo_code,
          policy_amount: original_price,
          customer_id: customer_id,
          insurance_plan_id: bai_car_insurance_policy_id,
          insurance_type: "car",
          linkType:'validate-promo-use' ,
        };
        const response = await useUpdateCustomerData(promoPayload);

        if (response.status == 200) {
          disable_sale_price.value = true;
          const { discount_amount, final_amount, discount_value, discount_percentage } = response.data.data;
          addInvoiceBodyParam.value.sale_price = final_amount;
          // addInvoiceBodyParam.value.promo_discount = discount_amount;
          addInvoiceBodyParam.value.is_discount_given = (discount_amount > 0) ? "1" : "0";

          // set split payment to No and disable
          if(addInvoiceBodyParam.value.sale_price == 0 && Number(discount_value) == 100 && discount_percentage == 1) {
            addInvoiceBodyParam.value.is_split_payment = '0'
            addInvoiceBodyParam.value.installments = []
            addInvoiceBodyParam.value.split_payment_installments_count = ""
            splitPaymentDisabled.value = true
            validateSalePrice.value = false // do not validate
          }

        } else {
          addInvoiceBodyParam.value.sale_price = addInvoiceBodyParam.value.original_price;
          // addInvoiceBodyParam.value.promo_discount = 0;
          addInvoiceBodyParam.value.is_discount_given = "0";
          rules.value.promo_code.show = true;
          rules.value.promo_code.message = response.response.data.message;
          disable_sale_price.value = false;
          // emit("validatePromoData", 0);
        }

        
        showContentLoader.value = false;
      }
    } else {
      // emit("validatePromoData", 0);
      addInvoiceBodyParam.value.sale_price = addInvoiceBodyParam.value.original_price;
      addInvoiceBodyParam.value.is_discount_given = "0";
      // addInvoiceBodyParam.value.promo_discount = 0;
      disable_sale_price.value = false;
      showContentLoader.value = false;
    }
  }

  onBeforeMount(() => {
         window.addEventListener("beforeunload", preventNav)
    })


    function preventNav(event) {
      if (!isClick.value) return
      event.preventDefault()
      event.returnValue = ""
    }

    const changeIsSplitPayment = (event) => {
      rules.value.is_split_payment.show = false;
      addInvoiceBodyParam.value.is_split_payment = event.target.value;
      addInvoiceBodyParam.value.split_payment_installments_count = "";
      rules.value.installment_payment_date.show = false;
      rules.value.installment_payment_date.index = [];
      rules.value.installment_amount.show = false;
      rules.value.installment_amount.index = [];
      rules.value.installment_cib_receipt.show = false;
      rules.value.installment_cib_receipt.index = [];
      rules.value.installment_amount_total.show = false;
      // rules.value.installment_cib_exists.show = false;
      // rules.value.installment_cib_exists.index = [];

      addInvoiceBodyParam.value.installments.forEach(installment => {
          installment.installment_no           = '';
          installment.installment_payment_date = '';
          installment.installment_amount       = '';
          installment.installment_cib_receipt  = '';
        });
    }

    const onChangeSplitPaymentInstallments = () => {
      rules.value.split_payment_installments_count.show = false;
      let installmentCount = addInvoiceBodyParam.value.split_payment_installments_count;
      if (installmentCount !== '' && parseInt(installmentCount) > 0) {
        addInvoiceBodyParam.value.installments = Array.from({ length: Number(installmentCount) }, (_, i) => ({
          ...splitPaymentInstallmentsParam.value,
          installment_no: i + 1,
          defDate: i == 1 ? paymentDate.value : null,
          installment_payment_date: i == 0 ? paymentDate.value : null,
        }))

      }
    };


    const installmentCountText = (index) => {
      let suffix = '';
      switch (index) {
        case 1:
          suffix = "st";
          break;
        case 2:
          suffix = "nd";
          break;
        case 3:
          suffix = "rd";
          break;
        case 4:
          suffix = "th";
          break;
        default:
          suffix = "";
      }
      return `${index}<sup>${suffix}</sup> Installment`;
    }

    const isSplitPaymentDatePickerDisabled = (index) => {
        if(index == 0){
          return true;
        }
        let mainPaymentDate = paymentDate.value;
        let installments    = addInvoiceBodyParam.value.installments;
        if(!mainPaymentDate || mainPaymentDate.length <= 0){
          return true;
        }else{
          if(index > 1){
            let prevDate = installments[index-1].installment_payment_date;
            if(!prevDate || prevDate.length <= 0){
              return true;
            }
          }
        }
        return false;
    };

    // const splitPaymentDatePickerMin = (index,type='') => {
    //       if (paymentDate.value && paymentDate.value.length > 0) {
    //         if (index > 0) {
    //           let installments = addInvoiceBodyParam.value.installments;
    //           let prevDate = installments[index - 1] ? installments[index - 1].installment_payment_date : null;

    //           if (prevDate) {
    //             let prevDateObj = new Date(prevDate);
    //             if (!isNaN(prevDateObj.getTime())) {
    //               let defDate = new Date(prevDateObj.getTime() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
    //               return (type == 'default') ? moment(defDate).format('YYYY-MM-DD') : defDate;
    //             }
    //           }
    //           let newDate = new Date(new Date(paymentDate.value).getTime() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);;
    //           return (type == 'default') ? moment(newDate).format('YYYY-MM-DD') : newDate;
    //         } else {
    //           let newDate = new Date(new Date(paymentDate.value).getTime() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
    //           return (type == 'default') ? moment(newDate).format('YYYY-MM-DD') : newDate;
    //         }
    //       }
    //       return null;
    //  }

     const splitPaymentDatePickerMin = (index) => {
                if (paymentDate.value && paymentDate.value.length > 0) {
                    if (index > 0) {
                        let installments = addInvoiceBodyParam.value.installments;
                        let prevDate = installments[index - 1] ? installments[index - 1].installment_payment_date : null;
                        if (prevDate) {
                          let prevDateMoment = moment(prevDate);
                            if (prevDateMoment.isValid()) {
                                return prevDateMoment.add(1,'day').format('YYYY-MM-DD');
                            }
                        }
                    }
                    let paymentDateMoment = moment(paymentDate.value);
                    if (paymentDateMoment.isValid()) {
                        return paymentDateMoment.add(1,'day').format('YYYY-MM-DD');
                    }
                }
                return null;
            }

     const splitPaymentDatePickerDefault = (index) => {
          if (paymentDate.value && paymentDate.value.length > 0) {
              if (index > 0) {
                  let installments = addInvoiceBodyParam.value.installments;
                  let prevDate = installments[index - 1] ? installments[index - 1].installment_payment_date : null;
                  if (prevDate) {
                    let prevDateMoment = moment(prevDate);
                      if (prevDateMoment.isValid()) {
                          return prevDateMoment.add(1,'d').format('YYYY-MM-DD');
                      }
                  }
              }
              let paymentDateMoment = moment(paymentDate.value);
              if (paymentDateMoment.isValid()) {
                  return paymentDateMoment.add(1,'d').format('YYYY-MM-DD');
              }
        }
          return null;
     }


    const installmentEnterData = (index,type) => {
        let installments = addInvoiceBodyParam.value.installments;
        let rulesKey     = Number(index).toString();
        if(installments){
          if(type == 'cib'){
            if(installments[rulesKey].installment_cib_receipt.length > 0){
              let allCibErrIndexes = rules.value.installment_cib_receipt.index;
              removeIndex(index,allCibErrIndexes);
              // let allCibValdIndexes = rules.value.installment_cib_exists.index;
              // removeIndex(index,allCibValdIndexes);
              // let currentValue = installments[rulesKey].installment_cib_receipt;
              // let isDuplicate = installments.some((installment, i) => i !== index && installment.installment_cib_receipt === currentValue);
                // if (isDuplicate) {
                    // rules.value.installment_cib_exists.index.push(rulesKey);
                    // rules.value.installment_cib_exists.show = true;
                    // return;
                // } else {
                    // removeIndex(index, rules.value.installment_cib_exists.index);
                // }
            }
            if (installments[index].installment_cib_receipt.length > 50) {
              addInvoiceBodyParam.value.installments[index].installment_cib_receipt = addInvoiceBodyParam.value.installments[index].installment_cib_receipt.slice(0, 50);
            }

          }
          if(type == 'installment_amount'){
            if(installments[index].installment_amount > 0){
              let allAmountErrIndexes = rules.value.installment_amount.index;
              removeIndex(index,allAmountErrIndexes);
            }
          }
          if(type == 'payment_date'){
            const first_installment = addInvoiceBodyParam.value.installments[index]
            const installment = addInvoiceBodyParam.value.installments[index + 1]
            if(installment) {
              installment.defDate = first_installment.installment_payment_date
              installment.installment_payment_date = '';
              addInvoiceBodyParam.value.installments = addInvoiceBodyParam.value.installments.map((installment, key) => {
                                if (key > index) {
                                  installment.installment_payment_date = '';
                                }
                                return installment;
              });
              }

              let allPaymentErrIndexes = rules.value.installment_payment_date.index;
              removeIndex(index,allPaymentErrIndexes);
          }

          let tinsAmount =0;
          installments.forEach(function(ins) {
            tinsAmount += parseFloat(ins.installment_amount);
          });

          if(tinsAmount != addInvoiceBodyParam.value.sale_price){
            rules.value.installment_amount_total.show = false;
            rules.value.installment_amount_total.type = '';
          }


        }

    }

    const removeIndex = (selectedIndex: string, allErrIndexes: string[] = []) => {
      if(allErrIndexes){
          const indexPosition = allErrIndexes.indexOf(selectedIndex.toString());
          if (indexPosition > -1) {
            allErrIndexes.splice(indexPosition, 1); // Remove the element at indexPosition
          }
      }
    }

    const validateAmount = (event, index, type='') => {
      let value = event.target.value;

      const decimalParts = value.split('.');
      if (decimalParts.length > 2) {
        decimalParts.splice(2, decimalParts.length - 2);
        value = decimalParts.join('.');
      }

      if (value.startsWith('.')) {
        value = '0' + value;
      }
      if (!value.includes('.') && value.startsWith('0')) {
        value = value.replace(/^0+/, '');
        // If all leading zeros were removed, ensure at least one zero remains
        if (value === '') {
          value = '0';
        }
      }


      // Remove all non-numeric characters except dot
      value = value.replace(/[^0-9.]/g, '');

      // Split the value on the decimal point
      const parts = value.split('.');

      // Limit to 9 digits before the decimal point
      if (parts[0].length > 9) {
        parts[0] = parts[0].substring(0, 9);
      }

      // Limit to 2 digits after the decimal point
      if (parts[1]?.length > 2) {
        parts[1] = parts[1].substring(0, 2);
      }

      // Rejoin the parts
      event.target.value = parts.join('.');

      if(event.target.value == '' || event.target.value.length == 0){
        event.target.value = 0
      }
      // Update the v-model value
      addInvoiceBodyParam.value.installments[index].installment_amount = event.target.value;

      if(type == 'installment_amount'){
        installmentEnterData(Number(index),type);
      }

    }

    return {
      splitPaymentDisabled,
      isPaymentWarning,
      router,
      hasPendingInvoice,
      validatePromoCode,
      fixFigureAdminFee,
      fixFigureAdminExtra,
      masterAdmin,
      paymentDate, 
      fixFigure,
      salesAgentList,
      invoiceModalRef,
      cancenRef,
      toCancel,
      toExpiryDate,
      toLanguage,
      toOrderDescription,
      focusField,
      roles,
      updateSalePriceValue,
      addDayDate,
      isDecimalValidPrice,
      is_agency_repair_disabled,
      isDecimalValidSalesPrice,
      onChangeEmail,
      ElNotification,
      editClaimsCertificate,
      editClaims,
      getPolicyBeginDate,
      resetClaims,
      isError,
      openModal,
      closeModal,
      onChangeAgencyRepair,
      agencyRepairList,
      onChangeInsurancePlan,
      calculateDiscountAmount,
      onChangePolicyPrice,
      companyList,
      setDate,
      leadStatus,
      ncc,
      uaeExperience,
      homeExperience,
      getThirdParty,
      getPolicyExpiry,
      editQuote,
      copyPlan,
      isEmailError,
      isClick,
      errorMessages,
      addInvoiceBodyParam,
      addInvoice,
      handleFileUpload,
      expiryDate,
      getExpiryDateTime,
      resetSalesAgent,
      errMsg,
      showInvoiceDom,
      agentList,
      onSalesAgentChange,
      setPaymentMode,
      updateCustomerEmail,
      rules,
      customerEmail,
      disableCustomerEmailButton,
      disabledDate,
      cancelledPoliciesList,
      onSelectQuoteRefNo,
      resetQuoteRefNo,
      changeDiscountGiven,
      otherGenders,
      paymentMethodLabel,
      disable_sale_price,
      showContentLoader,
      disabledButton,
      changeIsSplitPayment,
      onChangeSplitPaymentInstallments,
      installmentCountText,
      isSplitPaymentDatePickerDisabled,
      splitPaymentDatePickerMin,
      installmentEnterData,
      validateAmount,
      onPaymentDateChange,
      common
    };
  },
});
